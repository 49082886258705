import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { forwardRef } from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Input } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";

import Table from "../Tables/Table";
import "../App.css";

function RecordsSoloByClass(props) {
  const [post, setPost] = useState();
  const { rank } = props.match.params;
  const { class_name } = props.match.params;
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const [data, setData] = useState([]);
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#ff9100",
      },
      secondary: {
        main: "#ff9100",
      },
      type: "dark",
    },
  });

  // console.log(data);
  console.log("Count: ", data);
  useEffect(() => {
    (async () => {
      const result = await axios(`https://psucta.com/api/stats/solo/${rank}/${class_name}`);
      setData(result.data);
    })();
  }, []);

  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
      <div className="quest-rank">
      <Link to="/records/solo/8" onClick={() => window.location.href = "/records/solo/8"}>Ultimate</Link>
      <Link to="/records/solo/7" onClick={() => window.location.href = "/records/solo/7"}>S4</Link>
      <Link to="/records/solo/6" onClick={() => window.location.href = "/records/solo/6"}>S3</Link>
      <Link to="/records/solo/5" onClick={() => window.location.href = "/records/solo/5"}>S2</Link>  
              </div>
        <MaterialTable
          title={
            <div className="leaderboard-type">
              
              <h4>{rank} Solo Records — {class_name}</h4>
              <div className="player-count">
              <Link to={`/records/solo/Ultimate/${class_name}`} onClick={() => window.location.href = `/records/solo/Ultimate/${class_name}`}>Ultimate</Link>
      <Link to={`/records/solo/S4/${class_name}`} onClick={() => window.location.href = `/records/solo/S4/${class_name}`}>S4</Link>
      <Link to={`/records/solo/S3/${class_name}`} onClick={() => window.location.href = `/records/solo/S3/${class_name}`}>S3</Link>
      <Link to={`/records/solo/S2/${class_name}`} onClick={() => window.location.href = `/records/solo/S2/${class_name}`}>S2</Link>
      </div>
              <div className="player-type">
              <Link to={`/records/solo/7`}>All</Link>
              <Link to={`/records/solo/${rank}/Fighgunner`}  onClick={() => window.location.href = `/records/solo/${rank}/Fighgunner`}>FI</Link>
              <Link to={`/records/solo/${rank}/Guntecher`}  onClick={() => window.location.href = `/records/solo/${rank}/Guntecher`}>GT</Link>
              <Link to={`/records/solo/${rank}/Wartecher`}  onClick={() => window.location.href = `/records/solo/${rank}/Wartecher`}>WT</Link>
              <Link to={`/records/solo/${rank}/Fortefighter`}  onClick={() => window.location.href = `/records/solo/${rank}/Fortefighter`}>FF</Link>
              <Link to={`/records/solo/${rank}/Fortegunner`}  onClick={() => window.location.href = `/records/solo/${rank}/Fortegunner`}>FG</Link>
              <Link to={`/records/solo/${rank}/Fortetecher`}  onClick={() => window.location.href = `/records/solo/${rank}/Fortetecher`}>FT</Link>
              <Link to={`/records/solo/${rank}/Protranser`}  onClick={() => window.location.href = `/records/solo/${rank}/Protranser`}>PT</Link>
              <Link to={`/records/solo/${rank}/Acrotecher`}  onClick={() => window.location.href = `/records/solo/${rank}/Acrotecher`}>AT</Link>
              <Link to={`/records/solo/${rank}/Acrofighter`}  onClick={() => window.location.href = `/records/solo/${rank}/Acrofighter`}>AF</Link>
              <Link to={`/records/solo/${rank}/Fighmaster`}  onClick={() => window.location.href = `/records/solo/${rank}/Fighmaster`}>FM</Link>
              <Link to={`/records/solo/${rank}/Gunmaster`}  onClick={() => window.location.href = `/records/solo/${rank}/Gunmaster`}>GM</Link>
              <Link to={`/records/solo/${rank}/Masterforce`}  onClick={() => window.location.href = `/records/solo/${rank}/Masterforce`}>MF</Link>
              <Link to={`/records/solo/${rank}/Acromaster`}  onClick={() => window.location.href = `/records/solo/${rank}/Acromaster`}>AM</Link>
              </div>
            </div>
          }
          columns={[
            {
              title: "",
              field: "profile_pictures",
              width: "100%",
              render: (rowData) => (
                <img
                  src={rowData.profile_picture}
                  style={{ width: 40, borderRadius: "50%" }}
                />
              ),
              filtering: false,
              sorting: false,
              searchable: false,
            },
            {
              title: "Player Names",
              field: "player_name",
              render: (rowData) => (
                <Link to={`/players/${rowData.player_id}`}>
                  {rowData.player_name}
                </Link>
              ),
              editComponent: (props) => (
                <Input
                  defaultValue={props.value || "null"}
                  onChange={(e) => props.onChange(e.target.value)}
                  type="string"
                />
              ),
            },
            {
              title: "Mission Name",
              field: "mission_name",
              render: (rowData) => (
                <Link to={`/missions/solo/${rowData.mission_name}`}>
                  {rowData.mission_name}
                </Link>
              ),
            },
            { title: "Clear Time", field: "fastest_time" },
            {
              title: "Class Names",
              field: "player_class",
              render: (rowData) => (
                <Link to={`/records/solo/S4/${rowData.player_class}`}>
                  {rowData.player_class}
                </Link>
              ),
            },
            { title: "Races", field: "player_race" },

            { title: "Player Level", field: "player_level" },

            
            // { title: "Patch", field: "patch" },
            {
              title: "Validation Type",
              field: "run_validation",
              render: (rowData) => (
                <Link to={`/runs/${rowData.run_id}`}>
                  {rowData.validation_type ? rowData.validation_type : "Mission Log"}
                </Link>
              ),
            },
            {
              title: "Date Added",
              field: "created_date",
              type: "date",
              cellStyle: {
                whiteSpace: 'nowrap'
               },
              render: (rowData) => {
                const date = new Date(rowData.created_date);
                // Add 6 hours to the date
                date.setHours(date.getHours() + 6);
                return date.toLocaleString();
              },
            },
          ]}
          data={data}
          icons={tableIcons}
          options={{
            filtering: true,
            paging: false,
            headerStyle: { fontWeight: 700 },
            paginationType: "stepped",
            rowStyle: {
              height: "50px",
            },
          }}
        />
      </MuiThemeProvider>
    </div>
  );
}

export default RecordsSoloByClass;
